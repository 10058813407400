.photo-day-app.memory-mate-mode {
  .app-main {
    margin-left: 0 !important;
  }

  .product-progress-bar {
    margin-top: 50px;
  }

  .poster-image-container {
    margin-bottom: 50px;
  }

  .team-poster-image, .producedImage {
    width: 100vmin;
  }

  .column {
    &.team-poster-preview {
      width: 80%;

      .dropzone {
        width: 100%;
      }

      .demo-image-container {
        width: 100%;
        height: auto;
        min-height: 40vmin;
      }

      .demo-image {
        &.loaded-poster {
          width: 100%;
        }
      }

      .demo-image-controller {
        position: absolute;
        top: 0;
      }
    }
  }
}