.home-header {
    text-align: left;
}

.card {
    height: 510px;
}

@media (max-width: 768px) {
    .card {
        height: auto;
        margin-bottom: 20px;
    }
}

.card-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}