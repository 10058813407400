body {
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    color: #22222d;
    place-items: center;
}

*::selection {
    background-color: #eebeff;
}

.header {
    font-family: 'Mulish', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-top: 4vh;
    place-items: center;

    img {
        display: block;
        width: 120px;
        cursor: pointer;

        &.small {
            width: 80px;
        }
    }

    span {
        font-size: 45px;
        width: 60vw;
        margin-top: 20px;
        text-align: center;

        &.title {
            min-height: 120px;
        }
    }

    @media only screen and (max-width: 520px) {
        span {
            font-size: 50px;
            text-align: left;

            &.title {
                display: inline-block;
                width: 90vw;
                overflow-wrap: break-word;
            }
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-width: 50vw;
    margin: 25px auto;
    padding: 20px;
    border-radius: 20px;
    background-color: #e9e1d8;
    place-items: center;

    &.edit {
        background-color: transparent;
    }

    @media only screen and (max-width: 520px) {
        width: calc(90vw - 40px);
    }
}

.file-picker-title, .file-picker-sub {
    font-family: 'Mulish', sans-serif;
    margin: 10px auto;
    text-align: center;
}

.file-picker-title {
    font-size: 32px;
    font-weight: bold;
    width: 100%;
}

.file-picker-icon {
    font-size: 50px;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.file-picker-sub {
    font-size: 20px;
    width: 80%;

    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.file-picker {
    padding: 0 20%;
    cursor: pointer;
}
